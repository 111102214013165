import { isPayByCamp } from './utils';

export const getKidSubscription = (kid) => {
  if (kid?.profile?.is_activated) {
    if (kid?.subscription?.subscription_id) {
      if (
        kid?.subscription?.nickname === 'Villagers' ||
        kid?.subscription?.nickname === 'Branches'
      ) {
        return 'Villagers';
      }
      if (
        kid?.subscription?.nickname === 'Roots' ||
        kid?.subscription?.nickname === 'Seedlings V2' ||
        kid?.subscription?.nickname === 'Seedlings'
      ) {
        return 'Seedlings';
      }
    } else {
      if (
        kid?.profile?.tribe_request_status === 'accept' &&
        isPayByCamp(kid?.school_details?.pay_by)
      ) {
        return 'Seedlings';
      } else {
        return 'Emeralites';
      }
    }
  } else {
    return 'Unverified';
  }
};

const isCampSpecific = (camp) => {
  if (camp?.camp_list.length > 0) {
    return true;
  } else {
    return false;
  }
};

const isKidSubscribeToVillagers = (kid) => {
  if (!kid?.subscriptions?.subscription_id) {
    return false;
  } else {
    if (
      kid?.subscriptions?.nickname === 'Villagers' ||
      kid?.subscriptions?.nickname === 'Branches'
    ) {
      return true;
    } else {
      return false;
    }
  }
};

const isKidSubscribeToSeedlings = (kid) => {
  if (!kid?.subscriptions?.subscription_id) {
    if (kid?.school_details?.pay_by === 'by_camp') {
      return true;
    }
    return false;
  } else {
    if (
      kid?.subscriptions?.nickname === 'Seedlings V2' ||
      kid?.subscriptions?.nickname === 'Seedlings' ||
      kid?.subscriptions?.nickname === 'Roots' ||
      kid?.subscriptions?.nickname === 'Villagers' ||
      kid?.subscriptions?.nickname === 'Branches'
    ) {
      return true;
    } else {
      return false;
    }
  }
};

const isKidBelongsToCampList = (camp, kid) => {
  if (kid?.school_details?.id) {
    if (kid?.school_details?.id === camp?.camp_list[0].id) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isCampFreeOrNot = (camp, kid) => {
  if (camp?.is_paid) {
    if (camp?.is_free.includes('all') && !camp?.is_free.includes('seedling') && !camp?.is_free.includes('is_root')) {
      if (isCampSpecific(camp)) {
        if (isKidBelongsToCampList(camp, kid) && isKidSubscribeToVillagers(kid) || isKidSubscribeToSeedlings(kid)) {
          return true; // User needs to pay
        } else {
          return false; // Free for others
        }
      } else {
        if(isKidSubscribeToVillagers(kid) || isKidSubscribeToSeedlings(kid)){
          return true
        }else{

          return false; // User needs to pay as it applies to all
        }
      }
    }
   if (camp?.is_free.includes('is_root') && camp?.is_free.includes('all') && camp?.is_free.includes('seedling')) {
      if (isCampSpecific(camp)) {
        if (isKidBelongsToCampList(camp, kid)) {
          return false; // User needs to pay
        } else {
          return false; // User needs to pay (not in the camp list)
        }
      } else {
        return false; // All users need to pay for the highest plan
      }
    }
    if (camp?.is_free.includes('seedling') && camp?.is_free.includes('all')) {
      if (isCampSpecific(camp)) {
        if (isKidBelongsToCampList(camp, kid) && isKidSubscribeToVillagers(kid)) {
          return true; // Free for villagers in the specific camp
        } else {
          return false; // Paid for other users in the specific camp
        }
      } else {
        if (isKidSubscribeToVillagers(kid)) {
          return true; // Free for villagers
        } else {
          return false; // Paid for all other users
        }
      }
    }
    
    
    
  } else {
    if (camp?.is_free.includes('all') && camp?.is_free.includes('seedling') && camp?.is_free.includes('is_root')) {
      //free for all ,'seedling , is_root(villegers)'
      if (isCampSpecific(camp)) {
        if (isKidBelongsToCampList(camp, kid)) {
          return true;
        } else {
          false;
        }
      } else {
        return true;
      }
    }
    if (camp?.is_free.includes('is_root') && !camp?.is_free.includes('all') && !camp?.is_free.includes('seedling') 
    ) {
      // free for (is_root)villegers only
      if (isCampSpecific(camp) && isKidSubscribeToVillagers(kid)) {
        if (isKidBelongsToCampList(camp, kid)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (isKidSubscribeToVillagers(kid)) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (camp?.is_free.includes('seedling') && camp?.is_free.includes('is_root')) {
      //free for seedling and is_root(villegers)
      if (isCampSpecific(camp) && isKidSubscribeToSeedlings(kid) || isKidSubscribeToVillagers(kid)) {
        if (isKidBelongsToCampList(camp, kid)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (isKidSubscribeToVillagers(kid) || isKidSubscribeToSeedlings(kid) ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
};
